import React from 'react';

const NotFound = () => {
    return (
        <>
        <h1>NOT FOUND!!</h1>
        </>
    );
}

export default NotFound;
