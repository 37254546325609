import React, {useEffect, useState} from 'react';
import {MDBBtn, MDBSpinner} from "mdb-react-ui-kit";
import axios from "axios";
import ButtonHeader from "../Header/ButtonHeader";
import GetPodjezdService from "./GetPodjezdService";

const AddPodjezdSite = (props) => {
    const [pods, setPods] = useState(null);
    const getPodjezdItems = async () => {
        const response = await GetPodjezdService.getPodjezds(props.zid);
        setPods(response)
    }
    useEffect(() => {
        getPodjezdItems()
    }, [])


    function refr(response){
        setPods(response.data)
    }
    function add(id, zid) {
        axios.get('https://172.19.0.4:1234/addPodJezdId?Zapros=' + zid + "&AddPodJezdId=" + id)
            //     // .then(response => this.setState({ updatedAt: response.data.updatedAt }));
            //     .then(response => setGoods(response.good))
            .then(response => refr(response) )

        // {props.zid}
    }


    if (!pods || pods.length === 0)
        return <>
            <div className='text-center'>
                <MDBSpinner className='mx-2' color='warning'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
            </div>
        </>

    return (
        <>
            <h3></h3>
            <ButtonHeader value={"Какой подъезд нужно добавить?"} isnew={false} />
            {
                pods.pods.map((podgroup, index) =>
                    <div className="d-flex p-1 justify-content-center flex-shrink-1">
                        {podgroup.map((key, index) =>
                            <div className="p-1 flex-grow-1 d-grid gap-2"><MDBBtn onClick={() => add(key.id, props.zid)} id={key.id} color='success' outline={key.present}>{key.id}</MDBBtn></div>
                    )}
                    </div>
                )
            }
        </>
    );
};

export default AddPodjezdSite;