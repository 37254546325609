import React, {useEffect, useState} from 'react';
import GetCodeService from "../Api/GetCodeService";

import {
    MDBBtn, MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardText,
    MDBCardTitle,
    MDBSpinner
} from 'mdb-react-ui-kit';
import axios from "axios";
import {baseUrl_back} from "../../BaseUrl";
import {MdFavorite} from "react-icons/md";

const Favorites = ({uid}) => {
    const [token, setToken] = useState('');
    const [codeData, setCodeData] = useState(null);
    const [fName, setCFName] = useState(false);
    const [initDataUnsafe, setinitDataUnsafe] = useState( {});
    let xxx=""

    //РАСКОМЕНТАРИТЬ
    // try {
    //     let qid =JSON.stringify(initDataUnsafe.query_id)
    //
    //     if (qid!=undefined){
    //         setCFName(true)
    //     }
    // } catch (err) {
    //     console.log("Не телега");
    // }

    function refrDelFavor(response){
        // console.log(response.data)
        setCodeData(response.data)
    }
    function delFavor(id,uid) {
        // const response = await axios.get(`${baseUrl_back}/getCode?Zapros=`+zid+'&Pod='+pod)
        axios.get(`${baseUrl_back}/delFavotites?id=` + id+"&uid="+uid)
            .then(response =>refrDelFavor(response)  )
    }
    function webviewClose() {
        window.Telegram.WebApp.close();
    }


    window.Telegram.WebApp.MainButton
        .setText('Закрыть')
        .show()
        .onClick(function(){ webviewClose(); });





    const getFavorData = async () => {
        // const response = await GetCodeService.getCodeData(zid, pod);
        const response = await GetCodeService.getFavorData(uid)
        // console.log(response)
        setCodeData(response)
    }

    useEffect(() => {
        // try {
        //     setinitDataUnsafe(window.Telegram.WebApp.initDataUnsafe || {})
        // }  catch (err) {
        //     console.log("Не телега");
        // }
        getFavorData()
        window.Telegram.WebApp.expand()}, [])

    // if (fName!=false)
    //     return <>
    //         <div style={{paddingLeft: "15px", paddingRight: "15px",paddingTop: "15px"}}  className='text-center'>
    //             <MDBCard  alignment='center'>
    //                 <MDBCardHeader>Что-то пошло не по плану</MDBCardHeader>
    //                 <MDBCardBody>
    //                     <MDBCardTitle>Данные доступны только из приложения Telegram</MDBCardTitle>
    //                     <MDBCardText>Перейдите в бота @DomoKeyBot  и повторите запрос </MDBCardText>
    //                     {/*<MDBCardText>Перейдите по ссылке  </MDBCardText>*/}
    //                     <MDBBtn href='https://t.me/DomoKeyBot'>Перейти</MDBBtn>
    //                 </MDBCardBody>
    //                 <MDBCardFooter className='text-muted'>заботимся о вашей безопасности и приватности</MDBCardFooter>
    //             </MDBCard>
    //         </div>
    //     </>

    if (!codeData || codeData.length === 0)
        return <>
            <div className='text-center'>
                <MDBSpinner className='mx-2' color='warning'>
                    <span className='visually-hidden'>Загрузка...</span>
                </MDBSpinner>
            </div>
        </>



    return (
        <>
            {/*<h5>{xxx}</h5>*/}
            {/*<GroupCodesList Elements={codeData} zid={zid}/>*/}
            {codeData?.map((c) => (
                <>
                    <div className="d-grid gap-1"
                         style={{backgroundColor: '#3c3f41'}}>
                        <MDBBtn style={{backgroundColor: '#3c3f41'}} color='dark'
                                size='sm'>{c.adr}</MDBBtn>
                    </div>
                    <div style={{paddingRight: "15px", paddingLeft: "15px"}}>
                        <div className="d-flex">
                            <div className="p-1 flex-grow-1 text-center">
                                {c.CodesX?.map((xa) => (
                                    <div style={{paddingRight: "15px", paddingLeft: "15px"}}>
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 text-center">
                                                <div className="d-grid gap-2">
                                                    <MDBBtn style={{backgroundColor: '#2b2b2b'}} href='#'>
                                                        {xa.code}
                                                    </MDBBtn>
                                                </div>
                                            </div>
                                            <div className="p-2">

                                                <button type="submit"
                                                        className="btn btn-warning position-relative"
                                                        onClick={() => delFavor(xa.id,uid)}>
                                                    <MdFavorite/>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


                </>
            ))}

            {/*                  <div className="p-2">*/}
            {/*                      <button  type="submit"*/}
            {/*                              className="btn btn-success position-relative">*/}
            {/*                          <i className="fas fa-thumbs-up"></i>*/}
                      {/*                          <span*/}
                      {/*                              className="position-absolute top-0 start-100 translate-middle badge  badge-success">*/}
                      {/*  {1}*/}
                      {/*                              <span className="visually-hidden">unread messages</span>*/}
                      {/*</span>*/}
                      {/*                      </button>*/}
                      {/*                  </div>*/}
                      {/*                  <div className="p-2">*/}

                      {/*                      <button*/}
                      {/*                              type="submit"*/}
                      {/*                              className="btn btn-danger position-relative">*/}
                      {/*                          <i className="fas fa-thumbs-down"></i>*/}
                      {/*                          <span*/}
                      {/*                              className="position-absolute top-0 start-100 translate-middle badge rounded-bottom badge-danger">*/}
                      {/*  {1}*/}
                      {/*                              <span className="visually-hidden">unread messages</span>*/}
                      {/*</span>*/}
                      {/*                      </button>*/}
                      {/*                  </div>*/}
                      {/*              </div>*/}

                                {/*</div>*/}

                                {/*<div className="p-1 flex-grow-1 text-center">*/}
                                {/*    /!*<div className="d-grid gap-2">*!/*/}



                                {/*    /!*</div>*!/*/}
                                {/*</div>*/}

                            {/*</div>*/}

                            {/*<div className="p-2">*/}
                            {/*    {(!favotires) ? (*/}
                            {/*            <button onClick={() => addFavor(id, zid, true)} type="submit"*/}
                            {/*                    className="btn btn-outline-primary position-relative">*/}
                            {/*                <MdFavorite/>*/}
                            {/*            </button>) :*/}
                            {/*        (*/}
                            {/*            <button onClick={() => addFavor(id, zid, true)} type="submit"*/}
                            {/*                    className="btn btn-warning position-relative">*/}
                            {/*                <MdFavorite/>*/}
                            {/*            </button>)*/}
                            {/*    }*/}
                            {/*</div>*/}
                            {/*      <div className="p-2">*/}
                            {/*          <button disabled={!enabled} onClick={() => add(id, zid, true)} type="submit"*/}
                            {/*                  className="btn btn-success position-relative">*/}
                            {/*              <i className="fas fa-thumbs-up"></i>*/}
                            {/*              <span*/}
                            {/*                  className="position-absolute top-0 start-100 translate-middle badge  badge-success">*/}
                            {/*  {goods}*/}
                            {/*                  <span className="visually-hidden">unread messages</span>*/}
                            {/*</span>*/}
                            {/*          </button>*/}
                            {/*      </div>*/}
                            {/*<div className="p-2">*/}

                            {/*          <button disabled={!enabled} onClick={() => add(id, zid, false)} type="submit"*/}
                            {/*                  className="btn btn-danger position-relative">*/}
                            {/*              <i className="fas fa-thumbs-down"></i>*/}
                            {/*              <span*/}
                            {/*                  className="position-absolute top-0 start-100 translate-middle badge rounded-bottom badge-danger">*/}
                            {/*  {bads}*/}
                            {/*                  <span className="visually-hidden">unread messages</span>*/}
                      {/*</span>*/}
                      {/*          </button>*/}
                      {/*      </div>*/}
                      {/*  </div>*/}

                    {/*</div>*/}

{/*<SmartCaptcha language="ru" sitekey={'grKyPKs6AhuJc7AVQmh7hRVKnbt5SnQI1b0DqcvJ'} onSuccess={setToken}/>*/
}

</>
)
    ;
};


export default Favorites;