import axios from "axios";
import {baseUrl_back} from "../../BaseUrl";
export default class GetCodeService {
    static async getCodeData(zid,pod) {
        try {

            const response = await axios.get(`${baseUrl_back}/getCode?Zapros=`+zid+'&Pod='+pod)
            return response.data
        } catch (e) {
            console.log(e)
        }
    }


    static async getFavorData(uid) {
        try {

            const response = await axios.get(`${baseUrl_back}/getFavorites?uid=`+uid)
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
}




