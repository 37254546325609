import React, {useState} from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import {
    MDBBtn,
    MDBIcon,
    MDBInput, MDBInputGroup,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader,
    MDBModalTitle
} from "mdb-react-ui-kit";
import "./AddCodeDialog.css"
import {SmartCaptcha} from "@yandex/smart-captcha";
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';



function onChange(value) {
    console.log("Captcha value:", value);
}

const AddCodeButton = ({Adress, Podjezd, sLimit}) => {

    const [basicModal, setBasicModal] = useState(false);
    const [token, setToken] = useState(false);
    const [pod, setPod] = useState(Podjezd);

    const toggleShow = () => {
        setBasicModal(!basicModal);

        // setToken("None")
    }



    return (
        <>

            <div  style={{ padding: "15px 15px 15px 15px"}} className="d-grid gap-2">
                <MDBBtn hidden={!sLimit}  onClick={toggleShow} className='mx-2'outline color='success'>
                    <MDBIcon icon="plus-square" /> "Добавить свой код"
                </MDBBtn>
            </div>
            <MDBModal  animationDirection={"right"} show={basicModal} setShow={setBasicModal} tabIndex='-1'>
                <MDBModalDialog className="ModalForm">
                    <MDBModalContent >
                        <MDBModalHeader style={{ borderColor:'#2b2b2b' }}  className='mydark'>
                            <MDBModalTitle >Добавление кода</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleShow}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody id='form1'  className="mylightdark">

                            <MDBInput className="txtt" contrast label='Адрес' value={Adress} id='form1' type='text' />
                            <MDBInput className="txtt" contrast label='Подъезд' value={pod} id='form1' type='text' />
                            <MDBInput className="txtt" contrast label='Код домофона' id='form1' type='text' />
                            {/*<div className="g-recaptcha" style="width: 100%; "*/}
                            {/*     data-sitekey="6LeT0mQcAAAAANuCEuJ6xVmNEQZhenSDEHBijn9s"*/}
                            {/*     data-callback="recaptchaCallback"></div>*/}
                            {/*<SmartCaptcha id="ddddd" language="ru" sitekey={'grKyPKs6AhuJc7AVQmh7hRVKnbt5SnQI1b0DqcvJ'} onSuccess={setToken}/>*/}
                            {/*https://cloud.yandex.ru/docs/smartcaptcha/concepts/react*/}
                            {/*{token}*/}
                        </MDBModalBody>
                        <MDBModalFooter style={{ backgroundColor: '#2b2b2b', color:"white", borderColor:'#2b2b2b' }}  className=" text-white">
                            <MDBBtn  color='secondary' onClick={toggleShow}>
                                Закрыть
                            </MDBBtn>
                            <MDBBtn>Сохранить</MDBBtn>
                        </MDBModalFooter>

                    </MDBModalContent>
                </MDBModalDialog>

            </MDBModal>
        </>
    )
};

export default AddCodeButton;