import React from 'react';
import {MDBBadge, MDBBtn, MDBBtnGroup} from "mdb-react-ui-kit";

const Ostatok = (props) => {
    return (
        <div className="d-grid gap-2" >
            <MDBBtnGroup size='sm' aria-label='Basic example' >
                <MDBBtn style={{backgroundColor: '#3c3f41'}} color='dark' size='sm'>Осталось:
                    <MDBBadge className='ms-2' color='danger'>{props.Elements.UserOst}</MDBBadge>
                </MDBBtn>

                <MDBBtn style={{backgroundColor: '#3c3f41'}} color='dark' size='sm'>Лимит в сутки:
                    <MDBBadge className='ms-2' color='danger'>{props.Elements.UserLimit}</MDBBadge>
                </MDBBtn>
            </MDBBtnGroup>
        </div>
    );
};

export default Ostatok;
