import React, {useState} from 'react';
import "./Codestring.css"
import {MDBBtn} from 'mdb-react-ui-kit';
import axios from "axios";
import {baseUrl_back} from "../../BaseUrl";
import { MdFavorite } from "react-icons/md";
const CodeStringNew = ({islimit,value, good, bad, enabled, id, zid,fav}) => {
    const [goods, setGoods] = useState(good);
    const [bads, setBads] = useState(bad);
    const [favotires, setfavotires] = useState(fav);
    // console.log(favotires)
    function refr(response){
        setGoods(response.data.good)
        setBads(response.data.bad)
        setfavotires(response.data.faV)
        // console.log(response.data)
    }

    function refrFavor(response){
        // console.log(response.data)
        setfavotires(response.data.faV)
    }
    function add(id, zid, good) {
        // const response = await axios.get(`${baseUrl_back}/getCode?Zapros=`+zid+'&Pod='+pod)
        axios.get(`${baseUrl_back}/setCodeRating?Zapros=` + zid + "&codeId=" + id + "&Good=" + good)
            //     // .then(response => this.setState({ updatedAt: response.data.updatedAt }));
            //     .then(response => setGoods(response.good))
            .then(response => refr(response) )
    }

    function addFavor(id, zid, good) {
        // const response = await axios.get(`${baseUrl_back}/getCode?Zapros=`+zid+'&Pod='+pod)
        axios.get(`${baseUrl_back}/addToFavotites?Zapros=` + zid + "&codeId=" + id + "&Good=" + good)
            .then(response => refrFavor(response) )
    }

    return (
        <div style={{paddingRight: "15px", paddingLeft: "15px"}}>
            <div className="d-flex">
                <div className="p-2 flex-grow-1 text-center">
                    <div className="d-grid gap-2">
                        <MDBBtn style={{backgroundColor: '#2b2b2b'}} href='#'>
                            {value}
                        </MDBBtn>
                    </div>
                </div>
                <div className="p-2">
                    {(!favotires)?(
                    <button  onClick={() => addFavor(id, zid, true)} type="submit"
                            className="btn btn-outline-primary position-relative">
                        <MdFavorite />
                    </button>):
                        (
                            <button  onClick={() => addFavor(id, zid, true)} type="submit"
                                     className="btn btn-warning position-relative">
                                <MdFavorite />
                            </button>)
                }
                </div>
                <div className="p-2">
                    <button disabled={!enabled} onClick={() => add(id, zid, true)} type="submit"
                            className="btn btn-success position-relative">
                        <i className="fas fa-thumbs-up"></i>
                        <span
                            className="position-absolute top-0 start-100 translate-middle badge  badge-success">
                        {goods}
                            <span className="visually-hidden">unread messages</span>
                      </span>
                    </button>
                </div>
                <div className="p-2">

                    <button disabled={!enabled} onClick={() => add(id, zid, false)} type="submit"
                            className="btn btn-danger position-relative">
                        <i className="fas fa-thumbs-down"></i>
                        <span
                            className="position-absolute top-0 start-100 translate-middle badge rounded-bottom badge-danger">
                        {bads}
                            <span className="visually-hidden">unread messages</span>
                      </span>
                    </button>
                </div>
            </div>

        </div>
    );
};

export default CodeStringNew;
