import axios from "axios";

export default class GetPodjezdService {
    static async getPodjezds(zid) {
        try {
            const response = await axios.get('https://172.19.0.4:1234/getPod2Add?Zapros='+zid+'&GetPod2Add=true')
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
}