import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import AppRoute from "./Components/AppRoute";
import "./index.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
       <AppRoute/> {/*Все перенаправления и привязки URL*/}
    </BrowserRouter>
);

