import React from 'react';
import {MDBBadge, MDBBtn} from "mdb-react-ui-kit";

const ButtonHeader = ({value, isnew}) => {

    return (
        <div className="d-grid gap-2">
            <MDBBtn className='mx-2' style={{backgroundColor: '#f87300'}}>
                                 {value}

                {/*отображение иконки new*/}
                {isnew ? <MDBBadge className='ms-2'>NEW</MDBBadge> : ""}

            </MDBBtn>
        </div>
    );
};
//
export default ButtonHeader;
