import React from 'react';
import {MDBBadge, MDBBtn, MDBIcon} from "mdb-react-ui-kit";
import axios from "axios";

const BuyPremium = (props) => {
    function add( text, cost,mescol) {
        axios.get('https://172.19.0.4:1234/addInvoce?Zapros=' + props.zid + "&AddInvoce=" + cost+"&Name="+text+"&mesCol="+mescol)
            //     // .then(response => this.setState({ updatedAt: response.data.updatedAt }));
            //     .then(response => setGoods(response.good))
    }
    if (!props.sLimit)
        return <>
            <div className='text-center'>

            </div>
        </>
    console.log(props.sLimit)
    return (
        <div>
            <div className="d-grid gap-2">
                <MDBBtn className='mx-2' style={{backgroundColor: '#ffa900', marginTop:"1%"}}>
                    <MDBIcon fas icon="ban"/> Превышен суточный лимит
                </MDBBtn>
            </div>
            <div className="d-grid gap-2">
                <MDBBtn className='mx-2' style={{backgroundColor: '#2b2b2b'}}>
                    <MDBIcon far icon="credit-card"/> Убрать ограничения можно купив подписку через меню бота
                </MDBBtn>
            </div>

            <div className="d-grid gap-2">
                <MDBBtn className='mx-2' style={{backgroundColor: '#0e87de',marginTop:"1%"}}>
                    <MDBIcon fas icon="question-circle"/> Как подписаться?
                </MDBBtn>
            </div>

            <div className="d-grid gap-2">
                <MDBBtn className='mx-2' style={{backgroundColor: '#2b2b2b'}}>
                    Нажать в боте: "Меню->Подписка"
                </MDBBtn>
            </div>
            <div className="d-grid gap-2">
                <MDBBtn className='mx-2' style={{backgroundColor: '#2b2b2b'}}>
                    или
                </MDBBtn>
            </div>
            <div className="d-grid gap-2">
                <MDBBtn className='mx-2' style={{backgroundColor: '#2b2b2b'}}>
                    Отправить боту команду '/sub'
                </MDBBtn>
            </div>
            <div className="d-grid gap-2">
                {/*<MDBBtn className='mx-2' style={{backgroundColor: '#2b2b2b'}}>*/}
                    <p  style={{color: '#ffffff', paddingLeft:"3%", textAlign:"center", paddingTop:"3%"}}>После подключения подписки будет снято ограничение на 7 запросов в сутки. Количество запросов по подписке не ограничено. </p>
                {/*</MDBBtn>*/}
            </div>


            {/*<div className="d-flex p-1 justify-content-center flex-shrink-1">*/}
            {/*    <div className="p-1">*/}
            {/*        <MDBBtn onClick={() => add("Премиум на 1 месяц", 99,1)} color='success' outline={false}>1 мес   (99₽)</MDBBtn>*/}
            {/*    </div>*/}
            {/*    <div className="p-1">*/}
            {/*        <MDBBtn onClick={() => add("Премиум на 3 месяца", 240,3)} color='success' outline={false}>3 мес  (240₽)</MDBBtn>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="d-flex p-1 justify-content-center flex-shrink-1">*/}
            {/*    <div className="p-1 flex-shrink-1">*/}
            {/*        <MDBBtn onClick={() => add("Премиум на 6 месяцев", 420,6)} color='success' outline={false}>6 мес  (420₽)</MDBBtn>*/}

            {/*    </div>*/}
            {/*    <div className="p-1 flex-shrink-1">*/}
            {/*        <MDBBtn onClick={() => add("Премиум на 9 месяцев", 540,9)} color='success' outline={false}>9 мес  (540₽)</MDBBtn>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="d-flex p-1 justify-content-center flex-shrink-1">*/}
            {/*    <div className="p-1">*/}
            {/*        <MDBBtn onClick={() => add("Премиум на 1 год", 600,12)} color='success' outline={false}>1 год  (600₽)</MDBBtn>*/}
            {/*    </div>*/}
            {/*    <div className="p-1">*/}
            {/*        <MDBBtn onClick={() => add("Премиум на 2 года", 960,24)} color='success' outline={false}>2 года (960₽)</MDBBtn>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="d-flex p-1 justify-content-center flex-shrink-1">*/}
            {/*    <div className="p-1">*/}
            {/*        <MDBBtn onClick={() => add("Премиум навсегда", 1500,600)} color='success' outline={false}>Навсегда  (1500₽)</MDBBtn>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default BuyPremium;
