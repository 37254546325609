import React from 'react';
import ButtonHeader from "../Header/ButtonHeader";
import CodeString from "./CodeString";
import {MDBBtn, MDBIcon} from "mdb-react-ui-kit";

const CodesItem = (props) => {

    return (
        <div>

            <ButtonHeader value={props.GroupName} isnew={false}/>
            <div>
                {props.Elements.map((item, index) =>
                    <CodeString islimit={props.islimit} key={index} value={item.code} good={item.good} bad={item.bad}
                                enabled={true} id={item.id} zid={props.zid}/>
                )}
            </div>
        </div>
    );
};

export default CodesItem;
