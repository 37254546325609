import React from 'react';
import {MDBBadge, MDBBtn, MDBBtnGroup} from "mdb-react-ui-kit";

const Premium = (props) => {
    return (
        <div className="d-grid gap-2" >
            <MDBBtn style={{backgroundColor: '#3c3f41'}} color='dark' size='sm'>Premium доступ до :
                <MDBBadge className='ms-2' color='success'>{props.Elements.premium_text}</MDBBadge>
            </MDBBtn>
        </div>
    );
};

export default Premium;
