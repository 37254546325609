import React, {useEffect, useState} from 'react';
import GetCodeService from "../Api/GetCodeService";
import GroupCodesList from "./GroupCodesList";
import {MDBSpinner} from 'mdb-react-ui-kit';




const CodesManager = ({isLoading, zid, pod}) => {
    const [token, setToken] = useState('');
    const [codeData, setCodeData] = useState(null );
    const [fName, setCFName] = useState("noTelega" );
    let xxx=""
    //
    // alert(zid)
     const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe || {};

    //РАСКОМЕНТАРИТЬ
    try {
        //initDataUnsafe.user.first_name

        console.log(initDataUnsafe.user.first_name)
        xxx=" "
    } catch (err) {
        xxx="НЕ ТЕЛЕГА"
        console.log("Не телега");
    }


    function webviewClose() {
        window.Telegram.WebApp.close();
    }


    window.Telegram.WebApp.MainButton
        .setText('Закрыть')
        .show()
        .onClick(function(){ webviewClose(); });






    const getCodeItems = async () => {
        const response = await GetCodeService.getCodeData(zid, pod);
        setCodeData(response)
        // console.log(response)
    }

    useEffect(() => {
        getCodeItems()
        window.Telegram.WebApp.expand()

    }, [])


    if (!codeData || codeData.length === 0)
        return <>
            <div className='text-center'>
                <MDBSpinner className='mx-2' color='warning'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
            </div>
                </>



    return (
        <>

            {/*<h5>{xxx}</h5>*/}
            <GroupCodesList Elements={codeData} zid={zid}/>

            {/*<SmartCaptcha language="ru" sitekey={'grKyPKs6AhuJc7AVQmh7hRVKnbt5SnQI1b0DqcvJ'} onSuccess={setToken}/>*/}

        </>
    );
};

export default CodesManager;
