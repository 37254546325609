import React from 'react';
import {Routes, Route } from "react-router-dom";
import CodesManager from "./Codes/CodesManager";
import AddPodjezdSite from "./AddPodjezd/AddPodjezdSite";
import NotFound from "./NotFound/NotFound";
import CodesManager_new from "./Codes_new/CodesManager_new";
import Favorites from "./Favorites/favorites";



function useQuery() {
    return new URLSearchParams(window.location.search);
}


const AppRoute = () => {

    let zaprosid = useQuery().get('Zapros');
    let podjezd = useQuery().get('Pod');
    let uid=useQuery().get('uid');
    return (

            <Routes>
                <Route path="/">
                    {/*<Route path="/" element={<Main/>} />*/}
                    {/*<Route path="123" element={<UploadMap/>}/>*/}
                    {/*<Route path="reg" element={<ProMenuManager/>}/>*/}
                    <Route path="codestring" element={<CodesManager zid={zaprosid} pod={podjezd}/>}/>
                    <Route path="codestringnew" element={<CodesManager_new zid={zaprosid} pod={podjezd}/>}/>
                    <Route path="AddPod" element={<AddPodjezdSite zid={zaprosid}/>}/>
                    <Route path="favorites" element={<Favorites uid={uid}/>}/>
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>

    );
};

export default AppRoute;
