import React, {useEffect, useState} from 'react';
import GetCodeService from "../Api/GetCodeService";
import GroupCodesList from "./GroupCodesList";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardText,
    MDBCardTitle,
    MDBSpinner
} from 'mdb-react-ui-kit';




const CodesManager_new = ({isLoading, zid, pod}) => {
    const [token, setToken] = useState('');
    const [codeData, setCodeData] = useState(null );
    const [fName, setCFName] = useState(false);
    let xxx=""


    //РАСКОМЕНТАРИТЬ
    // try {
    //     //initDataUnsafe.user.first_name
    //     let qid =JSON.stringify(initDataUnsafe.query_id)
    //
    //     if (qid!=undefined){
    //         setCFName(true)
    //     }
    //     // console.log(initDataUnsafe.user.id)
    //     xxx=" "
    // } catch (err) {
    //     xxx="НЕ ТЕЛЕГА"
    //     console.log("Не телега");
    //     console.log(err);
    // }


    function webviewClose() {
        window.Telegram.WebApp.close();
    }


    window.Telegram.WebApp.MainButton
        .setText('Закрыть')
        .show()
        .onClick(function(){ webviewClose(); });






    const getCodeItems = async () => {
        const response = await GetCodeService.getCodeData(zid, pod);
        setCodeData(response)
        // console.log(response)
    }

    useEffect(() => {
        getCodeItems()
        window.Telegram.WebApp.expand()
    }, [])


    if (!codeData || codeData.length === 0)
        return <>
            <div className='text-center'>
                <MDBSpinner className='mx-2' color='warning'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
            </div>
                </>
    // if (fName==false)
    //     return <>
    //         <div style={{paddingLeft: "15px", paddingRight: "15px",paddingTop: "15px"}}  className='text-center'>
    //             <MDBCard  alignment='center'>
    //                 <MDBCardHeader>Что-то пошло не по плану</MDBCardHeader>
    //                 <MDBCardBody>
    //                     <MDBCardTitle>Данные доступны только из приложения Telegram</MDBCardTitle>
    //                     <MDBCardText>Перейдите в бота @DomoKeyBot  и повторите запрос </MDBCardText>
    //                     {/*<MDBCardText>Перейдите по ссылке  </MDBCardText>*/}
    //                     <MDBBtn href='https://t.me/DomoKeyBot'>Перейти</MDBBtn>
    //                 </MDBCardBody>
    //                 <MDBCardFooter className='text-muted'>заботимся о вашей безопасности и приватности</MDBCardFooter>
    //             </MDBCard>
    //
    //         </div>
    //     </>


    return (
        <>

            {/*<h5>{xxx}</h5>*/}
            <GroupCodesList Elements={codeData} zid={zid}/>

            {/*<SmartCaptcha language="ru" sitekey={'grKyPKs6AhuJc7AVQmh7hRVKnbt5SnQI1b0DqcvJ'} onSuccess={setToken}/>*/}

        </>
    );
};

export default CodesManager_new;
