import React from 'react';
import ButtonHeader from "../Header/ButtonHeader";
import CodeStringNew from "./CodeStringNew";
import {MDBBtn, MDBIcon} from "mdb-react-ui-kit";

const CodesItem = (props) => {
   // console.log("Dddddddddddd")
    console.log(props.Elements)
    return (
        <div>

            <ButtonHeader value={props.GroupName} isnew={false}/>
            <div>
                {props.Elements.map((item, index) =>
                    <CodeStringNew islimit={props.islimit} key={index} value={item.code} good={item.good} bad={item.bad}
                                   enabled={true} id={item.id} zid={props.zid} fav={item.faV}/>
                )}
            </div>
        </div>
    );
};

export default CodesItem;
