import React from 'react';
import {ArrayHelper} from "./ArrayHelper";
import CodesItem from "./CodesItem";
import AddCodeButton from "../AddCodeDialog/AddCodeDialog";
import { MDBBadge, MDBBtn,MDBBtnGroup } from 'mdb-react-ui-kit';
import BuyPremium from "../BuyPremium/BuyPremium";
import Ostatok from "../Ostatok/Ostatok";
import Premium from "../Premium/Premium";

const GroupCodesList = (props) => {

    const groupCodesByType = ArrayHelper.groupBy(props.Elements.Codes, item => item.Type);

    return (
        <div>
            {/*<div className="d-grid gap-2">*/}
                {!props.Elements.premium ?  <Ostatok Elements={props.Elements}></Ostatok> : <Premium Elements={props.Elements}></Premium>}

            {/*</div>*/}
            <div className="d-grid gap-2" style={{backgroundColor: '#3c3f41',paddingBottom: '10px'}} >
                <MDBBtn style={{backgroundColor: '#3c3f41'}} color='dark' size='sm'>{props.Elements["_id"]}</MDBBtn>
            </div>

            {!props.Elements.isLimit ? <BuyPremium sLimit={!props.Elements.isLimit} zid={props.zid}></BuyPremium> :
                Object.keys(groupCodesByType).map((key, index) =>
                <CodesItem key={index} Elements={groupCodesByType[key]} GroupName={key} islimit={props.Elements.isLimit} zid={props.zid} />
            )
            }

            {/*<AddCodeButton Podjezd={props.Elements.Podjezd} Adress={props.Elements._id} sLimit={props.Elements.isLimit}/>*/}

        </div>
    );
};

export default GroupCodesList;
